<template>
  <div>
    
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            スタッフのダッシュボード
            <CSwitch
              class="float-right"
              size="sm"
              shape="pill"
              color="info"
              data-on="On"
              data-off="Off"
              :checked="true"
            />
          </CCardHeader>
          <CCardBody>
            スタッフ向け、ダッシュボード
            <div class="bd-example">
              <li class="h6">仕様確認中</li>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- TODO: -->
  </div>
</template>

<script>

export default {
  name: "DashboardList",
  data() {
    return {
      loading: false,
    };
  },
  computed: {

  },
  methods: {

  },
  beforeMount() {
    // 保持している宿ID初期化
    this.$store.commit('setUid',"");
    console.log("宿IDクリア")
    console.log(this.$store.state.uniqueId)
  },
};
</script>
